var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
const Info = (_a) => {
    var { unique = '' } = _a, props = __rest(_a, ["unique"]);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", role: "presentation", focusable: "false" }, props),
        React.createElement("path", { fill: "#000", fillRule: "evenodd", d: "M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10m1-15v2h-2V7zm0 10v-6h-2v6z", clipRule: "evenodd" })));
};
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
export default Info;
